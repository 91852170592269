(function() {

    if (!$('.product-Detail').length) {
        return;
    }

    var radio, variant, prev, curr, stock;

    $(document).on('change', 'label[data-variant]', function () {
        radio = $(this).find('input');
        variant = $('input[name=variant]:checked');

        stock = $(this).data('stock')

        var $scope = $('#frm-addToCartForm-quantity')
        var scope = $scope[0]

        var options = $scope.data('_options')

        if (!options) {
            options = Array.prototype.slice.call(scope.options)
            $scope.data('_options', options)
        }

        scope.innerHTML = ''
        options
            .filter(function(element, index) {
                return index < stock
            })
            .forEach(function(element) {
                scope.appendChild(element)
            })

        scope.value = 1

        $('.rubrique option').addClass(function() {
            return this.value;
        });

        curr = radio.val();

        if (prev == curr) {
            radio.closest('form')[0].reset();
        }

        $('#frm-addToCartForm-quantity option').removeClass('is-hidden')

        prev = variant.val();
    });

})();
